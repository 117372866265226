import './Footer.scss';
import footerRedImage from '../../../assets/images/18footer-red.png';
import beGambleAwareImage from '../../../assets/images/begambleawareorg-white.png';

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="mt-5">
      <div className="container">
        <div className="content">
          <div className="d-flex align-items-center flex-wrap">
            <img className="me-4" src={footerRedImage} alt="18 footer"/>
            <a className="me-4" href="https://www.begambleaware.org/" target="_blank">
              <img src={beGambleAwareImage} alt="Be Gameble Aware"/>
            </a>
            {/*<a className="me-4" href="https://www.gamblingcommission.gov.uk/" target="_blank">*/}
            {/*  <img className="me-4" src={gamblingCommissionImage} alt="Gambling commission"/>*/}
            {/*</a>*/}
          </div>
          <div className="mt-3">© {year} Astriona. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
}
